<template>
  <v-icon :size="size" :color="color" class="ml-1" @click="goToPath">{{ icon }}</v-icon>
</template>

<script>
export default {
  props: {
    routeName: { type: String, required: true },
    routeParams: { type: Object, default: () => ({}) },
    routeQuery: { type: Object, default: () => ({}) },
    criteria: { type: Array, default: () => [] },
    color: { type: String, default: "primary" },
    targetBlank: { type: Boolean, default: false },
    size: { type: Number, default: 18 },
    icon: {
      type: String,
      default: "mdi-filter",
      validator: v => v.startsWith("mdi-"),
    },
  },
  methods: {
    goToPath() {
      let newQuery = { ...this.routeQuery };
      const route = { name: this.routeName, params: this.routeParams };

      if (this.criteria.length) {
        newQuery.criteria = this.$encodeCriteria(this.criteria);
        route.query = { ...newQuery };
      }

      if (this.targetBlank) {
        let routeData = this.$router.resolve(route);
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style scoped></style>
